<template>
  <b-card-code
    no-body
    title="User List"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">

        <div class="col-md-12">
          <b-button
            v-if="checkPermission('create user')"
            v-b-modal.form-user-modal
            class="btn btn-info btn-sm mb-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Add User"
            @click="cleanUpForm()"
          >
            <feather-icon
              icon="PlusCircleIcon"
            />
            Add User
          </b-button>

          <ValidationObserver ref="simpleRules">
            <b-modal
              id="form-user-modal"
              no-close-on-backdrop
              size="lg"
              :title="editId != null ? 'Edit User' : 'Add User'"
            >
              <div class="form">
                <h5>Basic Information</h5>
                <div class="form-group">
                  <label for="">Referral Code:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Kode referal"
                    rules="required"
                  >
                    <b-form-input
                      v-model="formPayload.referral_code"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Referral code..."
                    />
                    <small
                      v-for="(validation, index) in validations.referral_code"
                      v-if="validations.referral_code"
                      :key="`errorName${index}`"
                      class="text-danger"
                    >{{ validation }}</small>
                  </validation-provider>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Name:</label>
                      <validation-provider
                        name="User/member name..."
                      >
                        <b-form-input
                          v-model="formPayload.name"
                          :state="validations.name ? false:null"
                          placeholder="User/member name..."
                        />
                        <small
                          v-for="(validation, index) in validations.name"
                          v-if="validations.name"
                          :key="`errorName${index}`"
                          class="text-danger"
                        >{{ validation }}</small>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Email:</label>
                      <validation-provider
                        name="User email..."
                      >
                        <b-form-input
                          v-model="formPayload.email"
                          :state="validations.email ? false:null"
                          placeholder="User email..."
                        />
                        <small
                          v-for="(validation, index) in validations.email"
                          v-if="validations.email"
                          :key="`errorEmail${index}`"
                          class="text-danger"
                        >{{ validation }}</small>
                      </validation-provider>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Phone Number:</label>
                      <validation-provider
                        name="User phone..."
                      >
                        <b-form-input
                          v-model="formPayload.phone_number"
                          :state="validations.phone_number ? false:null"
                          placeholder="User phone..."
                        />
                        <small
                          v-for="(validation, index) in validations.phone_number"
                          v-if="validations.phone_number"
                          :key="`errorPhone${index}`"
                          class="text-danger"
                        >{{ validation }}<br /></small>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Birthdate:</label>
                      <input
                        v-model="formPayload.birthdate"
                        type="date"
                        class="form-control"
                        placeholder="User birthdate..."
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Photo: (Optional)</label><br>
                      <b-form-file
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="changePhoto($event)"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div>
                      <small>Current photo:</small><br>
                      <img
                        v-if="photo_url !== null"
                        :src="photo_url"
                        style="max-width: 100%;"
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Password:</label>
                  <input
                    v-model="formPayload.password"
                    type="password"
                    class="form-control"
                    placeholder="User password..."
                  >
                </div>

                <div class="form-group">
                  <label for="">Password Confirmation:</label>
                  <input
                    v-model="formPayload.password_confirmation"
                    type="password"
                    class="form-control"
                    placeholder="User password..."
                  >
                </div>
              </div>

              <template #modal-footer="{}">
                <div v-if="isLoading">
                  <br>
                  <b-spinner
                    class="mb-2"
                    variant="primary"
                  /><br>
                </div>
                <b-button
                  variant="success"
                  :disabled="isLoading"
                  @click="editId == null ? createItem() : updateItem()"
                >
                  Save User
                </b-button>
              </template>
            </b-modal>
          </ValidationObserver>
        </div>

        <div class="col-12">
          <div class="form-row mb-1">
            <div class="col-lg-4 mb-1 mb-lg-0">
              <select
                v-model="filter.sort_by_register"
                class="form-control form-control-sm"
              >
                <option value="">
                  -- Sory By --
                </option>
                <option value="asc">
                  Oldest
                </option>
                <option value="desc">
                  Newest
                </option>
              </select>
            </div>
            <div class="col-lg-4 mb-1 mb-lg-0">
              <DateRangePicker v-model="register_range" />
            </div>
            <div class="col-lg-4 mb-1 mb-lg-0">
              <input
                v-model="filter.keyword"
                type="text"
                placeholder="Search by name or email"
                class="form-control form-control-sm float-right"
                style="width: 200px;"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>User</th>
            <!-- <th>Role</th> -->
            <th>Phone</th>
            <th>Status</th>
            <th>Registration Time</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="(item, index) in result.data"
            v-else
            :key="item.id"
          >
            <td>{{ item.name }}<br><small>{{ item.email }}</small></td>
            <!-- <td>
                <span v-if="item.roles.length == 0" class="badge text-capitalize badge-light-primary badge-pill">User</span>
                <span v-else v-for="role in item.roles" :key="role" class="badge text-capitalize badge-light-success badge-pill">{{ role }}</span>
            </td> -->
            <td>{{ item.phone_number == null ? '-' : item.phone_number }}</td>
            <td>
              <span
                class="badge text-capitalize badge-pill"
                :class="item.status == 'active' ? 'badge-light-success' : 'badge-light-secondary'"
              >{{ item.status }}</span>
            </td>
            <td>{{ item.registered_at == null ? '-' : item.registered_at | formatDateRegister }}</td>
            <td>
              <b-dropdown
                variant="info"
                size="sm"
              >
                <template #button-content>
                  Action
                </template>
                <b-dropdown-item
                  v-if="checkPermission('show users')"
                  @click="goToDetail(item.uuid)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-75"
                  />
                  Detail User
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('update user')"
                  @click="editItem(item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-75"
                  />
                  Edit User
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('delete user')"
                  @click="deleteItem(item.uuid)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-75"
                  />
                  Delete User
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('banned user')"
                  @click="item.status == 'banned' ? bannedItem('unbanned', item.uuid) : bannedItem('banned', item.uuid)"
                >
                  <feather-icon
                    :icon="item.status == 'banned' ? 'UnlockIcon' : 'LockIcon'"
                    class="mr-75"
                  />
                  {{ item.status == 'banned' ? 'Unbanned' : 'Banned' }} User
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner, BFormFile, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import DateRangePicker from '@/components/DatePicker.vue'

// Moment
import moment from 'moment'

Vue.use(VuejsDialog)

export default {
  title() {
    return `User List`
  },
  components: {
    ValidationObserver,
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BFormFile,
    ValidationProvider,
    BFormInput,
    DateRangePicker,
  },
  watch: {
    register_range(value) {
      this.filter.register_range = value[0] != null && value[1] != null ? this.formatDateRange(value[0], value[1]) : ''
    },
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      locale: 'id',
      required,
      currentPage: 1,
      result: {},
      filter: {
        keyword: '',
        user_type: '',
        sort_by_register: '',
        register_range: '',
      },
      register_range: {
        startDate: null,
        endDate: null,
      },
      isLoading: false,
      formPayload: Object,
      validations: '',
      editId: null,
      // roles: [],
      restrictedCommodities: [],
      countries: [],
      provinces: [],
      cities: [],
      photo_url: null,
    }
  },
  created() {
    this.getData()
    this.getRoles()
  },
  methods: {
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone_number: '',
        photo: '',
        birthdate: '',
        referral_code: null,
      }
      this.photo_url = null
      this.validations = ''
    },
    changePhoto(event) {
      this.formPayload.photo = event.target.files[0]
      this.showPhoto(event.target.files[0])
    },
    showPhoto(file) {
      const reader = new FileReader()
      reader.onload = e => {
        this.photo_url = e.target.result
      }

      reader.readAsDataURL(file)
    },
    createItem() {
      const form = this.preparePayload()
      this.isLoading = true

      localize(this.locale)

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$http.post('/admin/users', form, {
            headers: { 'content-type': 'multipart/form-data' },
          })
            .then(response => {
              this.$bvModal.hide('form-user-modal')
              this.getData(this.currentPage)
              successNotification(this, 'Success', 'User successfully created')
              this.cleanUpForm()
              this.isLoading = false
            })
            .catch(error => {
              if (error.response.data.meta.validations) {
                this.validations = error.response.data.meta.validations
              }
              this.isLoading = false
            })
        }
        this.isLoading = false
      })
    },
    updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      this.$http.post(`/admin/users/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-user-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'User successfully updated!')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    preparePayload() {
      const form = new FormData()
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      // Prepare role payload
      // this.formPayload.roles.forEach(item => {
      //   form.append('roles[]', item)
      // })

      return form
    },
    bannedItem(type, id) {
      this.$swal({
        title: 'Are you sure?',
        text: `Are you sure to ${type} this user?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`admin/users/${id}/${type}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: `User successfully ${type}`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    editItem(item) {
      this.cleanUpForm()

      this.editId = item.uuid

      // Getting roles id
      const roleId = []
      const context = this
      item.roles.forEach(roleName => {
        context.roles.forEach(role => {
          if (roleName == role.name) {
            roleId.push(role.id)
          }
        })
      })

      this.formPayload = {
        name: item.name,
        email: item.email,
        epact_vendor_id: item.epact_vendor_id,
        password: item.password,
        password_confirmation: item.password_confirmation,
        phone_number: item.phone_number,
        photo: item.photo,
        roles: roleId,
        referral_code: item.referral_code,
      }
      this.photo_url = item.photo_url

      this.$bvModal.show('form-user-modal')
    },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = page
      queryParams.user_type = 'normal'

      this.$http.get('/admin/users', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    getRoles() {
      this.$http.get('/admin/acl/roles')
        .then(response => {
          this.roles = response.data.data.data
          // this.isLoading = false
        })
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this user?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`admin/users/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'User successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    goToDetail(id) {
      this.$router.push({ name: 'users.show', params: { id } })
    },
    formatDateRange(startDate, endDate) {
      return `${moment(startDate).format('Y-MM-DD')}:${moment(endDate).format('Y-MM-DD')}`
    },
  },
}
</script>
