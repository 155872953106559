<template>
  <date-picker
    v-model="register_range"
    input-class="form-control form-control-sm"
    type="date"
    range
    placeholder="Select register range"
  />
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  props: ['value'],
  components: {
    DatePicker,
  },
  computed: {
    register_range: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
